import { Center, Container, Text, Title } from "@mantine/core";
import Head from "next/head";
import Image from "next/image";
import Link from "next/link";
import { PRIMARY_TITLE_COLOR } from "../lib/colors";
import logoImage from "../public/logo.png";

export default function Custom404() {
  return (
    <>
      <Head>
        <title>Seite nicht gefunden - EnglischfürSchüler.de</title>
      </Head>

      <Container mt="xl" size="xs">
        <Center>
          <Link href="/" passHref>
            <Image alt="" src={logoImage} width={175} height={50} />
          </Link>
        </Center>

        <Title
          order={1}
          mt="lg"
          style={{
            color: PRIMARY_TITLE_COLOR,
          }}
        >
          Seite nicht gefunden
        </Title>

        <Text mt="xs">
          Die von Ihnen angefragte Seite wurde nicht gefunden.{" "}
          <Link href="/">Zurück zur Startseite</Link>
        </Text>
      </Container>
    </>
  );
}
